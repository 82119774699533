import React from 'react';
import './veranstaltungsuebersicht.css';
import BasicStructure from '../../components/basic-structure/basic-structure';
import { TextBox } from '../../components/textbox/textbox';
import TitleMetaTags from '../../components/title-meta-tags/title-meta-tags';
import Header from '../../components/header/header';
import Menubar from '../../components/menu/menubar';
import BurgerMenu from '../../components/burgermenu/burgermenu';
import Footer from '../../components/footer/footer';

interface Props {
  data: any;
}

interface PageContext {
  allVeranstaltung: PageMeta[];
  parentTitle?: string;
  centerTiles?: boolean;
}

export default function Veranstaltungsuebersicht(props: {
  pageContext: PageContext;
}): JSX.Element {
  const pageContext = props.pageContext;
  let veranstaltungsUebersichtContainerClass = pageContext.centerTiles
    ? 'veranstaltungsuebersicht__container veranstaltungsuebersicht__container_centered'
    : 'veranstaltungsuebersicht__container';
  veranstaltungsUebersichtContainerClass += props.path.includes('archiv')
    ? ' archiv__veranstaltungsuebersicht__container '
    : '';
  return (
    <BasicStructure>
      <TitleMetaTags title="Veranstaltungen" />
      <Header>
        <Menubar styleClass="main-menu" />
        <div className="veranstaltungsuebersicht__header">
          <BurgerMenu />
        </div>
      </Header>
      {(pageContext.parentTitle && (
        <h1 className="page-title veranstaltungsuebersicht-title">
          {pageContext.parentTitle}
        </h1>
      )) || <div></div>}
      <div className={veranstaltungsUebersichtContainerClass}>
        {pageContext.allVeranstaltung.map((veranstaltung) => {
          return (
            <TextBox
              key={`${veranstaltung.name}-ubersicht-box`}
              url={veranstaltung.url}
              name={
                veranstaltung.name.includes('Archiv')
                  ? veranstaltung.name.toUpperCase()
                  : veranstaltung.name
              }
              subtitle={veranstaltung.subtitle}
            />
          );
        })}
      </div>

      <Footer />
    </BasicStructure>
  );
}
